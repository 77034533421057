/* You can add global styles to this file, and also import other style files */
.custom-select {
    border-radius: .25rem;
}

.form-control {
    border-radius: .25rem;
}

.input-group-addon:not(:last-child) {
    border-bottom-left-radius: .25em;
    border-top-left-radius: .25em;
}

.top-buffer {
    margin-top:2%
}